var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.getIncidencias}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-reload")]),_vm._v(" Recargar ")],1),_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"fixed-header":"","items":_vm.incidencias,"headers":_vm.incidenciasHeader,"calculate-widths":true,"checkbox-color":"secondary","loading":_vm.loading,"footer-props":{ showFirstLastPage: true, showCurrentPage: true }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.incidencias},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.grupo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(item.grupo)+" ")])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (item.estado == 'Cerrada' ? 'error--text' : 'success--text')),domProps:{"textContent":_vm._s(item.estado)}})]}},{key:"item.fecha",fn:function(ref){
					var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.fecha))}})]}},{key:"item.acciones",fn:function(ref){
					var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){_vm.idIncidencia = item.idIncidencia}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-eye")]),_vm._v(" ver detalles ")],1)]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","transition":"dialog-bottom-transition","fullscreen":"","value":_vm.idIncidencia != null}},[_c('detalles-incidencia',{key:_vm.idIncidencia,attrs:{"idIncidencia":_vm.idIncidencia},on:{"close":function($event){_vm.idIncidencia = null;
				_vm.getIncidencias;},"reload":_vm.getIncidencias}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }