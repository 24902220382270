<template>
	<v-container fluid>
		<v-btn small @click="getIncidencias" color="primary">
			<v-icon small left>mdi-reload</v-icon>
			Recargar
		</v-btn>
		<v-card class="mt-2">
			<v-data-table
				fixed-header
				:items="incidencias"
				:headers="incidenciasHeader"
				:calculate-widths="true"
				checkbox-color="secondary"
				:loading="loading"
				:footer-props="{ showFirstLastPage: true, showCurrentPage: true }"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters
						:headers="headers"
						:items="incidencias"
						v-model="inlineFilters"
					></table-filters>
				</template>

				<template v-slot:item.grupo="{ item }">
					<v-chip outlined label color="primary">
						{{ item.grupo }}
					</v-chip>
				</template>

				<template v-slot:item.estado="{ item }">
					<span
						v-text="item.estado"
						:class="`${
							item.estado == 'Cerrada' ? 'error--text' : 'success--text'
						}`"
					/>
				</template>

				<template v-slot:item.fecha="{ item }">
					<span v-text="parseDate(item.fecha)" />
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-btn
						color="primary"
						small
						outlined
						@click="idIncidencia = item.idIncidencia"
					>
						<v-icon left small>mdi-eye</v-icon>
						ver detalles
					</v-btn>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			persistent
			transition="dialog-bottom-transition"
			fullscreen
			:value="idIncidencia != null"
		>
			<detalles-incidencia
				@close="
					idIncidencia = null;
					getIncidencias;
				"
				:key="idIncidencia"
				:idIncidencia="idIncidencia"
				@reload="getIncidencias"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import {
	processToken,
	perColumnFilter,
	parseDate,
	decode64,
} from "@/utils/index.js";

export default {
	components: {
		DetallesIncidencia: () => import("@/components/DetallesIncidencia.vue"),
		TableFilters: () => import("@/components/TableFilters.vue"),
	},
	props: {
		idCliente: String | Number,
		cups: String,
	},
	data() {
		return {
			idIncidencia: null,
			loading: true,
			incidencias: [],
			inlineFilters: {},
			grupoUsuario: JSON.parse(
				decode64(this.$store.getters.getToken.split(".")[1])
			).grupo,
			incidenciasHeader: [
				{ text: "No.", value: "idIncidencia" },
				{ text: "DNI cliente", value: "dniCliente" },
				{ text: "CUPS cliente", value: "cupsCliente" },
				{ text: "Usuario", value: "nombre" },
				{ text: "Agente", value: "nombreAgente" },
				{ text: "Fecha", value: "fecha", dataType: "date" },
				{ text: "Estado", value: "estado", dataType: "select" },
				{ text: "Grupo", value: "grupo", dataType: "select" },
				{
					text: "Acciones",
					value: "acciones",
					sortable: false,
					filterable: false,
				},
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
		};
	},
	mounted() {
		this.getIncidencias();
	},
	methods: {
		parseDate,
		decode64,
		getIncidencias() {
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/incidencias.php`,
				params: {
					token: this.$store.getters.getToken,
					cupsCliente: this.cups,
				},
			}).then(({ data }) => {
				let {tipoUsuario} =
					JSON.parse(decode64(this.$store.getters.getToken.split(".")[1]));
				this.incidencias = data.filter(
					(i) =>
						(this.grupoUsuario.split(",").includes(i.grupo) ||
						tipoUsuario == "Administrator")
				);
				this.loading = false;
			});
		},
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	background: #f1f1f1 !important;
}
</style>